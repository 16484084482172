.quiz-form-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quiz-form-cont .thankyou-img {
  width: 45%;
  margin-bottom: 2.5vh;
}

.quiz-form-cont .user-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: left;
  height: fit-content;
  width: 80%;
  margin: 0 auto;
  padding: 4vh 2vw;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: #d7c6f3;
}

.quiz-form-cont .user-form h2 {
  text-align: center;
  margin: 1vh 0;
  margin-bottom: 2vh;
}

.quiz-form-cont .user-form label {
  font-weight: bold;
  margin-bottom: 5px;
}

.quiz-form-cont .user-form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.quiz-form-cont .user-form > .country-select {
  width: 100%;
}

.quiz-form-cont .user-form .quiz-submit-btn {
  padding: 1.5vh 2vw;
  font-size: 1vmax;
  color: white;
  background-color: #d1410c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1vh;
  transition: background-color 0.3s ease;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .quiz-form-cont .thankyou-img {
    width: 50%;
    margin-bottom: 1.5vh;
  }

  .quiz-form-cont .user-form {
    width: 80%;
    padding: 2.5vh 4vw;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .quiz-form-cont .user-form h2 {
    font-size: 2vmax;
    margin-bottom: 1.2vh;
  }

  .quiz-form-cont .user-form label {
    font-size: 1.5vmax;
  }

  .quiz-form-cont .user-form input {
    font-size: 12px;
    padding: 6px;
  }

  .quiz-form-cont .user-form .quiz-submit-btn {
    padding: 1vh 3vw;
    font-size: 1.5vmax;
    margin-top: 0.6vh;
    border-radius: 3px;
  }
}

/* Responsive styles for max-width 400px */
@media (max-width: 400px) {
  .quiz-form-cont .thankyou-img {
    width: 50%;
    margin-bottom: 1.5vh;
  }

  .quiz-form-cont .user-form {
    width: 80%;
    padding: 2.5vh 4vw;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .quiz-form-cont .user-form h2 {
    font-size: 2vmax;
    margin-bottom: 1.2vh;
  }

  .quiz-form-cont .user-form label {
    font-size: 1.5vmax;
  }

  .quiz-form-cont .user-form > .country-select::placeholder{
    font-size: 1.5vmax;
  }

  .quiz-form-cont .user-form input {
    font-size: 12px;
    padding: 6px;
  }

  .quiz-form-cont .user-form .quiz-submit-btn {
    padding: 1vh 3vw;
    font-size: 1.5vmax;
    margin-top: 0.6vh;
    border-radius: 3px;
  }
}
