.analysis-fact-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: z;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1000;
}

.analysis-fact-screen > .fact-image {
  height: 30vh;
  margin-bottom: 2vh;
}

.analysis-fact-screen .fact-content {
  width: 50%;
  height: 25%;
  padding: 2vh 2vw;
  background-color: rgba(215, 198, 243, 0.4);
  border-radius: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.fact-content h2 {
  font-size: 2.5vmax;
  margin: 0;
}

.fact-content > .fact-p {
  font-size: 1.2vmax;
  font-weight: 400;
  margin: 0;
}

.fact-content > .fact-btn {
  padding: 1.75vh 2vw;
  background: #d1410c;
  color: white;
  border: none;
  font-size: 1vmax;
  border-radius: 5px;
  cursor: pointer;
  margin: 0;
  transition: 0.3s ease;
}

.fact-content > .fact-btn:hover {
  background: #d1410c;
  scale: 1.05;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .analysis-fact-screen .fact-content {
    width: 90%;
    height: 20%;
    padding: 1.2vh 1.2vw;
    border-radius: 6px;
  }
  .analysis-fact-screen > .fact-image {
    height: 25vh;
  }

  .fact-content h2 {
    font-size: 3vmax;
  }

  .fact-content > .fact-p {
    font-size: 1.45vmax;
  }

  .fact-content > .fact-btn {
    padding: 1.5vh 4vw;
    font-size: 1.3vmax;
    border-radius: 3px;
  }
}

/* Responsive styles for max-width 400px */
@media (max-width: 400px) {
  .analysis-fact-screen .fact-content {
    width: 90%;
    height: 20%;
    padding: 1.2vh 1.2vw;
    border-radius: 6px;
  }

  .fact-content h2 {
    font-size: 3vmax;
  }

  .fact-content > .fact-p {
    font-size: 1.45vmax;
  }

  .analysis-fact-screen > .fact-image {
    height: 25vh;
  }

  .fact-content > .fact-btn {
    padding: 1.5vh 4vw;
    font-size: 1.3vmax;
    border-radius: 3px;
  }
}