.container {
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5vmax;
}

.proposal-img {
  height: 70vh;
}

.header button {
  background-color: #d3d0d0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.event-details {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.event-details .page-title {
  color: #1e0a3c;
  font-weight: 800;
  font-size: 2.5em;
  word-break: break-word;
}
.event-details .yellow-strip {
  width: 100%;
  height: 8vh;
  background: #fff2c7;
  margin-top: 3vh;
  display: flex;
  align-items: center;
  gap: 2vw;
  padding: 0 1vw;
}
.event-details .yellow-strip > h6 {
  font-size: 1.1vmax;
  font-weight: 600;
  color: #5a5a5a;
}
.event-details .yellow-strip > a {
  font-size: 1.1vmax;
  font-weight: 500;
  padding: 1vh 2vw;
  color: #fff;
  border-radius: 1vmax;
  background-color: #d1410c;
  text-decoration: none;
}
.event-details .proposal-display > h2 {
  margin: 0;
  margin-top: 3vh;
  margin-bottom: 0.5vh;
}
.event-details .proposal-display > ul {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
}
.event-details .proposal-display > ul > li {
  margin-bottom: 15px;
  color: #5a5a5a;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}
.event-details-header {
  display: flex;
  align-items: center;
}
.event-details-header .left-half-event-details {
  flex: 3;
  margin-right: 10px;
}
.event-details-header .right-half-event-details {
  flex: 1;
  text-align: center;
}
.event-details h2 {
  margin: 0;
  margin-top: 1vh;
  margin-bottom: -0.5vh;
}
.event-details .key-takeaways {
  margin-top: 20px;
}
.event-details .key-takeaways h2 {
  margin: 0;
  margin-top: 3vh;
  margin-bottom: -0.5vh;
}
.event-details .key-takeaways ul {
  margin-top: 10px;
  padding-left: 20px;
}
.event-details .key-takeaways li {
  margin-bottom: 5px;
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
.event-details .key-takeaways li strong {
  font-weight: 600;
}
.event-details .key-takeaways .curriculum-download-btn {
  background-color: #d1410c;
  color: #fff;
  padding: 1.5vh 2vw;
  border-radius: 0.5vmax;
  border: none;
  font-size: 1vmax;
  cursor: pointer;
}

.organizer-cont > .organizer-box {
  background-color: #d7c6f3;
  height: 35vh;
  border-radius: 0.5vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.organizer-cont > .organizer-box > .organizer-up {
  width: 95%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.organizer-cont > .organizer-box > .organizer-up > img {
  width: 12.5vw;
}

.organizer-cont > .organizer-box > .organizer-up > a {
  text-decoration: none;
  color: white;
  padding: 1.5vh 2vw;
  background: #d1410c;
  border-radius: 0.5vmax;
}

.organizer-cont > .organizer-box > .organizer-down {
  width: 95%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.5vh;
  box-sizing: border-box;
}

.organizer-cont > .organizer-box > .organizer-down > h4 {
  margin: 0;
  font-size: 1.2vmax;
}

.organizer-cont > .organizer-box > .organizer-down > p {
  margin: 0;
  font-size: 1vmax;
  font-weight: 400;
}

.date-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date-info p {
  font-weight: 600;
  line-height: 0;
  margin-top: 5px;
}

.tags {
  margin-top: 20px;
  color: #6f7287;
}

.location {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}

.location .location_text_info {
  margin-left: 1em;
  margin-bottom: 0.2em;
  font-size: 14px;
  font-weight: 600;
  color: #6f7287;
}

.location .location_text_info .text {
  margin-top: 0.6em;
}

.about-event {
  display: flex;
  flex-direction: row;
}

.calendar {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.date-cell {
  width: 120px;
  padding: 10px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  background-color: #d4edda; /* Light Green */
  color: #155724;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.date-cell.selected {
  background-color: #155724; /* Dark Green */
  color: white;
  border-color: #0c3d19;
}
.date-cell:hover {
  background-color: #c3e6cb;
}

.about-event .about-event-text {
  margin-left: 1em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
.about-event-description p {
  font-weight: 400;
  color: #6f7287;
  font-size: 16px;
  line-height: 1.5rem;
}
.payment-info {
  margin-top: 20px;
  padding: 10px;
}
.contact-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 7vh;
  text-decoration: none;
  background-color: #d1410c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5vmax;
}
.larger-than-800 {
  display: block;
}
.payment-info .price {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.payment-info button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  background-color: #d1410c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.smaller-than-800 {
  display: none;
}
.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
}
.discounted-price {
  font-weight: 700;
  color: #333;
}
.ul-why-join {
  padding-left: 4vmax;
}
.event-details .bottom-h2 {
  margin-top: 4vh;
}
.share-popup-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  background: #0000007e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-popup-overlay > .share-popup {
  background: #fff;
  width: 50%;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vmax;
}
.share-popup-overlay > .share-popup > .share-head {
  height: 20%;
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-popup-overlay > .share-popup > .share-head > h3 {
  color: #000;
  font-weight: 400;
  font-size: 1.2vmax;
  width: 95%;
  text-align: center;
}
.share-popup-overlay > .share-popup > .share-cont {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}
.share-popup-overlay > .share-popup > .share-cont .share-button-cont {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.share-popup-overlay
  > .share-popup
  > .share-cont
  .share-button-cont
  > .share-button {
  background: #d1410c;
  display: flex;
  color: white;
  align-items: center;
  border: none;
  justify-content: center;
  padding: 0.75vh 0.57vw;
  border-radius: 0.3vmax;
}
.link-copy-container {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.link-box {
  flex: 1;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  cursor: default;
}
.copy-button {
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  background-color: #d1410c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.copy-button:hover {
  background-color: #361c5f;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal > form {
  background-color: #fff;
  padding: 2vmax;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 35%;
  height: 40%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vh;
  position: relative;
}
.modal > form > .modal-close {
  position: absolute;
  top: 2.5%;
  right: 2.5%;
}
.modal > form > h3 {
  font-size: 1.75vmax;
  margin: 0;
}
.modal > form > p {
  font-size: 1.05vmax;
}
.modal > form > .modal-submit {
  font-size: 1vmax;
}
.modal > form > .modal-submit:hover {
  background-color: #b9390a;
}

.modal > form > input {
  text-align: center;
}

.modal > form > .country-select {
  width: 100%;
}

.modal > form > .country-select::placeholder {
  text-align: start;
}

.quiz-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.quiz-popup > .quiz-popup-content {
  width: 45%;
  height: 35%;
  border-top: 2vh solid #d1410c;
  background-color: #fff6d6;
  border-radius: 0.5vmax;
  display: flex;
  align-items: start;
  justify-content: center;
  position: relative;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-close {
  position: absolute;
  top: -1%;
  right: 0;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-left {
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-left > img {
  width: 70%;
  object-fit: contain;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-right {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  padding-left: 1vmax;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-right > h3 {
  margin: 0;
  font-weight: 500;
  font-size: 1.4vmax;
  margin-bottom: -1vh;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-right > h3 > span {
  font-weight: 700;
  font-size: 1.6vmax;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-right > h2 {
  margin: 0;
  font-weight: 500;
  font-size: 1.4vmax;
}
.quiz-popup > .quiz-popup-content > .quiz-popup-right > a {
  height: 6vh;
  width: 14vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1.1vmax;
  background-color: #dc4914;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.curriculum-loader {
  width: 2vmax;
  padding: 1vmax;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #d1410c;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 1250px) {
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .smaller-than-800 {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8%;
  }
  .smaller-than-800 button {
    margin-top: 1em;
    width: 100%;
    height: 5vh;
    font-size: 1.5vmax;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }
}
@media (max-width: 1024px) {
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .smaller-than-800 {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .smaller-than-800 button {
    margin-top: 1em;
    padding: 10px 20px;
    width: 100%;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }
}
@media (max-width: 800px) {
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .smaller-than-800 {
    display: block;
    height: 5vh;
    position: fixed;
    bottom: 2.3%;
    width: 100%;
    z-index: 999;
  }
  .smaller-than-800 button {
    margin-top: 1em;
    padding: 10px 20px;
    width: 100%;
    font-size: 1.5vmax;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }
  .overlay .popup {
    width: 75vw;
  }
}
@media (max-width: 600px) {
  body {
    padding: 0 1vmax;
  }
  .page-container .upcoming-event-container {
    margin-top: 0;
    padding-top: 0;
  }
  .header img {
    width: 100%;
    height: 100%;
    margin: 1vh 0;
    margin-top: 2vh;
    object-fit: contain;
  }
  .contact-button {
    width: 30vw;
    height: 6vh;
    margin-right: 0;
  }
  .event-details .event-details-header .smaller-than-800 {
    display: flex;
    flex-direction: column;
    height: 10vh;
    position: fixed;
    bottom: 2.8%;
    width: 100%;
    z-index: 999;
    background-color: #fcffe2;
  }
  .event-details .event-details-header .smaller-than-800 .discounted-price {
    font-size: 1.8vmax;
    margin: 0;
  }
  .event-details .event-details-header .smaller-than-800 button {
    width: 100%;
    height: 90%;
    font-size: 2.2vmax;
    background-color: #d1410c;
    color: #fff;
  }
  .about-info {
    font-size: 15px;
    margin: 0;
    margin-top: 1vh;
  }
  .event-details .yellow-strip {
    height: 5vh;
    justify-content: space-evenly;
    gap: 2vw;
  }
  .event-details .yellow-strip > a {
    font-size: 1.2vmax;
    font-weight: 500;
    padding: 0.7vh 1.5vw;
  }
  .event-details .yellow-strip > h6 {
    font-size: 1.5vmax;
    width: fit-content;
    margin: 0;
  }
  .event-details .proposal-display > ul > li {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5rem;
  }
  .event-details .proposal-display > h2 {
    margin: 0;
    margin-top: 3vh;
    margin-bottom: 0.5vh;
    font-size: 2vmax;
  }
  .event-details .key-takeaways ul li {
    margin-bottom: 5px;
    color: #000;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5rem;
  }
  .share-popup-overlay > .share-popup {
    width: 90%;
    height: 35%;
    border-radius: 1vmax;
  }
  .share-popup-overlay > .share-popup > .share-head > h3 {
    font-size: 0.9rem;
  }
  .share-popup-overlay > .share-popup > .share-cont .share-button-cont {
    width: 95%;
    gap: 0.5rem;
  }
  .event-details .key-takeaways .curriculum-download-btn {
    font-size: 1.8vmax;
  }
  .link-copy-container {
    width: 90%;
    gap: 0.3rem;
  }
  .link-box {
    font-size: 0.7rem;
    padding: 0.3rem;
  }
  .copy-button {
    font-size: 0.7rem;
    padding: 0.5rem 0.6rem;
  }
  .overlay .popup {
    width: 75vw;
  }
  .modal > form {
    width: 80%;
    padding: 1rem;
  }
  .modal > form > h3 {
    font-size: 3vmax;
  }
  .modal > form > p {
    font-size: 1.1vmax;
  }
  .modal > form > input {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .modal > form > .modal-close {
    font-size: 1.25rem;
  }
  .modal > form > .modal-submit {
    font-size: 1.8vmax;
    padding: 1.5vh 2vw;
  }
  .quiz-popup > .quiz-popup-content {
    width: 90%;
    height: 40%;
    flex-direction: column;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-left {
    width: 100%;
    height: 50%;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-left > img {
    width: 30%;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right {
    width: 100%;
    height: 50%;
    gap: 2.5vh;
    justify-content: start;
    padding-left: 0;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > h3 {
    font-size: 2.1vmax;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > h3 > span {
    font-size: 2.5vmax;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > h2 {
    font-size: 2vmax;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > a {
    height: 4.5vh;
    width: 50vw;
    font-size: 1.8vmax;
  }

  .organizer-cont > .organizer-box {
    height: 35vh;
  }

  .organizer-cont > .organizer-box > .organizer-up {
    height: 35%;
    align-items: center;
  }

  .organizer-cont > .organizer-box > .organizer-up > img {
    width: 35vw;
  }

  .organizer-cont > .organizer-box > .organizer-up > a {
    padding: 0.8vh 2.5vw;
    font-size: 0.8rem;
  }

  .organizer-cont > .organizer-box > .organizer-down {
    gap: 1vh;
    height: 60%;
  }

  .organizer-cont > .organizer-box > .organizer-down > h4 {
    font-size: 2vmax;
  }

  .organizer-cont > .organizer-box > .organizer-down > p {
    font-size: 1.3vmax;
  }
}
@media (max-width: 400px) {
  .page-container .upcoming-event-container {
    margin-top: 0;
    padding-top: 0;
  }
  .header img {
    width: 100%;
    height: 100%;
    margin: 1vh 0;
    margin-top: 2vh;
    object-fit: contain;
  }
  .contact-button {
    width: 30vw;
    height: 6vh;
    margin-right: 0;
  }
  .event-details .event-details-header .smaller-than-800 {
    display: flex;
    flex-direction: column;
    height: 10vh;
    position: fixed;
    bottom: 2.9%;
    width: 100%;
    z-index: 999;
    background-color: #fcffe2;
  }
  .event-details .event-details-header .smaller-than-800 .discounted-price {
    font-size: 1.8vmax;
    margin: 0;
  }
  .event-details .event-details-header .smaller-than-800 button {
    width: 100%;
    height: 90%;
    font-size: 2.2vmax;
    background-color: #d1410c;
    color: #fff;
  }
  .about-info {
    font-size: 15px;
    margin: 0;
    margin-top: 1vh;
  }
  .event-details .yellow-strip {
    height: 5vh;
    justify-content: space-evenly;
    gap: 2vw;
  }
  .event-details .yellow-strip > a {
    font-size: 1.2vmax;
    font-weight: 500;
    padding: 0.7vh 1.5vw;
  }
  .event-details .yellow-strip > h6 {
    font-size: 1.5vmax;
    width: fit-content;
    margin: 0;
  }
  .event-details .proposal-display > ul > li {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5rem;
  }
  .event-details .proposal-display > h2 {
    margin: 0;
    margin-top: 3vh;
    margin-bottom: 0.5vh;
    font-size: 2vmax;
  }
  .event-details .key-takeaways ul li {
    margin-bottom: 5px;
    color: #000;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5rem;
  }
  .event-details .key-takeaways .curriculum-download-btn {
    font-size: 1.8vmax;
  }
  .share-popup-overlay > .share-popup {
    width: 90%;
    height: 35%;
    border-radius: 1vmax;
  }
  .share-popup-overlay > .share-popup > .share-head > h3 {
    font-size: 0.9rem;
    width: 80%;
  }
  .share-popup-overlay > .share-popup > .share-cont .share-button-cont {
    width: 95%;
    gap: 0.5rem;
  }
  .share-popup-overlay
    > .share-popup
    > .share-cont
    .share-button-cont
    > .share-button {
    width: 20%;
    padding: 1vh 0;
  }
  .link-copy-container {
    width: 90%;
    gap: 0.3rem;
  }
  .link-box {
    font-size: 0.7rem;
    padding: 0.3rem;
  }
  .copy-button {
    font-size: 0.7rem;
    padding: 0.5rem 0.6rem;
  }
  .overlay .popup {
    width: 75vw;
  }
  .modal > form {
    width: 80%;
    padding: 1rem;
  }
  .modal > form > h3 {
    font-size: 3vmax;
  }
  .modal > form > p {
    font-size: 1.1vmax;
  }
  .modal > form > input {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
  .modal > form > .modal-close {
    font-size: 1.25rem;
  }
  .modal > form > .modal-submit {
    font-size: 1.8vmax;
    padding: 1.5vh 2vw;
  }
  .quiz-popup > .quiz-popup-content {
    width: 90%;
    height: 40%;
    flex-direction: column;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-left {
    width: 100%;
    height: 50%;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-left > img {
    width: 30%;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right {
    width: 100%;
    height: 50%;
    gap: 2.5vh;
    justify-content: start;
    padding-left: 0;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > h3 {
    font-size: 2.1vmax;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > h3 > span {
    font-size: 2.5vmax;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > h2 {
    font-size: 2vmax;
  }
  .quiz-popup > .quiz-popup-content > .quiz-popup-right > a {
    height: 4.5vh;
    width: 50vw;
    font-size: 1.8vmax;
  }

  .organizer-cont > .organizer-box {
    height: 35vh;
  }

  .organizer-cont > .organizer-box > .organizer-up {
    height: 35%;
    align-items: center;
  }

  .organizer-cont > .organizer-box > .organizer-up > img {
    width: 35vw;
  }

  .organizer-cont > .organizer-box > .organizer-up > a {
    padding: 0.8vh 2.5vw;
    font-size: 0.8rem;
  }

  .organizer-cont > .organizer-box > .organizer-down {
    gap: 1vh;
    height: 60%;
  }

  .organizer-cont > .organizer-box > .organizer-down > h4 {
    font-size: 2vmax;
  }

  .organizer-cont > .organizer-box > .organizer-down > p {
    font-size: 1.3vmax;
  }
}
