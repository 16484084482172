.cont {
  max-width: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

.contact-content > p {
  text-align: center;
  font-size: 1.1em;
}

form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  margin: 0;
}

.contact-info {
  width: 50%;
  height: 44vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.contact-info h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 1.2vmax;
  margin: 0;
  color: black;
}

.contact-info h2 img {
  width: 35px;
  height: 35px;
}

.contact-info p {
  margin: 0;
  margin-left: 1.5vmax;
  font-size: 1.1vmax;
}

.contact-info .social-head {
  margin-top: 1vmax;
}

.contact-info .social-ico {
  display: flex;
  align-items: center;
  gap: 1vmax;
  margin-left: 1vmax;
}

.contact-info .social-ico img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.contact-info .social-ico .fb-img {
  width: 33px;
  height: 33px;
  margin-bottom: 1px;
}

.contact-form label {
  display: block;
  margin-bottom: 15px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

.phone-input-container {
  display: flex;
  align-items: center;
}

.country-code {
  width: 30%;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  background-color: #ffffff;
  font-size: 12px;
  padding: 13.3px 0;
  margin-bottom: 0.7vmax;
}

form textarea {
  width: 100%;
  height: 12.5vh;
  font-family: Arial, sans-serif;
  resize: none;
}

form textarea::placeholder {
  font-size: 1.1vmax;
}

.mobile-number {
  width: 70%;
  padding: 12px;
  border: 1px solid #ffffff;
  border-left: none;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
}

.c-btn {
  width: 100%;
  padding: 12px;
  background-color: #fbb83b;
  color: #000000;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.c-btn:hover {
  background-color: #d89e32;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-contact {
  background-color: #fbb83b;
  color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 1000;
}

.popup-contact p {
  margin: 0;
  font-size: 16px;
}

/* Responsive CSS for max-width: 800px */
@media (max-width: 800px) {
  .cont {
    flex-direction: column;
    padding: 10px;
  }

  form {
    width: 100%;
  }

  .contact-info {
    width: 100%;
    align-items: flex-start;
  }

  .contact-info h2 {
    font-size: 1.1em;
  }

  .contact-info h2 img {
    width: 30px;
    height: 30px;
  }

  .contact-info p {
    font-size: 0.9em;
  }

  .contact-info .social-ico {
    margin-top: 0.5em;
  }

  .contact-info .social-ico img {
    width: 35px;
    height: 35px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    font-size: 12px;
    padding: 8px;
  }

  .c-btn {
    font-size: 14px;
    padding: 8px;
  }

  h1 {
    font-size: 1.5em;
  }

  .contact-content > p {
    margin: auto;
    width: 90%;
    font-size: 0.7em;
  }

  .contact-content > .contact-p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .phone-input-container {
    flex-direction: column;
  }

  .country-code,
  .mobile-number {
    width: 100%;
  }

  .country-code {
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .mobile-number {
    border-radius: 4px;
  }

  .popup-contact {
    width: 80%; /* Reduce width to fit smaller screens */
    padding: 15px; /* Adjust padding */
    font-size: 14px; /* Reduce font size */
  }

  .popup-contact p {
    font-size: 14px; /* Smaller text */
  }
}

/* Responsive CSS for max-width: 400px */
@media (max-width: 400px) {
  .cont {
    flex-direction: column;
    padding: 10px;
  }

  form {
    width: 100%;
  }

  .contact-info {
    width: 100%;
    align-items: flex-start;
  }

  .contact-info h2 {
    font-size: 1.1em;
  }

  .contact-info h2 img {
    width: 30px;
    height: 30px;
  }

  .contact-info p {
    font-size: 0.9em;
  }

  .contact-info .social-ico {
    margin-top: 0.5em;
  }

  .contact-info .social-ico img {
    width: 35px;
    height: 35px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    font-size: 12px;
    padding: 8px;
  }

  .c-btn {
    font-size: 14px;
    padding: 8px;
  }

  h1 {
    font-size: 1.5em;
  }

  .contact-content > p {
    margin: auto;
    width: 90%;
    font-size: 0.7em;
  }

  .contact-content > .contact-p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .phone-input-container {
    flex-direction: column;
  }

  .country-code,
  .mobile-number {
    width: 100%;
  }

  .country-code {
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .mobile-number {
    border-radius: 4px;
  }

  .popup-contact {
    width: 90%; /* Even smaller width for narrow screens */
    padding: 10px; /* Less padding */
    font-size: 12px; /* Smaller font size */
  }

  .popup-contact p {
    font-size: 12px;
  }
}
