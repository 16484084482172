.container {
  max-width: 1200px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.analysis-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.analysis-header img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5vmax;
}

.header button {
  background-color: #d3d0d0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.box p {
  margin: 0;
  margin-bottom: 0.4vmax;
}

.larger-than-800 .button button {
  font-size: 1.15vmax;
}

.event-details {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.event-details .page-title {
  color: #1e0a3c;
  font-weight: 800;
  font-size: 2.5em;
  word-break: break-word;
}

.about-info {
  font-weight: 400;
}

.event-details-header {
  display: flex;
  align-items: center;
}

.event-details-header .left-half-event-details {
  flex: 3;
  margin-right: 10px;
}

.event-details-header .right-half-event-details {
  flex: 1;
  text-align: center;
}

.event-details .key-takeaways .da-btn {
  margin-bottom: 2vh;
}

.event-details .key-takeaways h3 {
  margin: 0;
}

.event-details .key-takeaways .analysis-ul {
  list-style-type: none;
}

.event-details .key-takeaways .analysis-ul li span {
  margin-left: 1.4vmax;
}

.event-details .key-takeaways li {
  margin-bottom: 5px;
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.event-details .key-takeaways li strong {
  font-weight: 600;
}

.curriculum-btn {
  font-size: 1vmax;
  background-color: #d1410c;
}

.curriculum-btn:hover {
  background-color: #c03b0b;
}

.ul-why-join {
  padding-left: 2.5vw;
}

.ul-why-join li {
  margin-bottom: 5px;
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  font-weight: 600;
  margin-top: 5px;
}

.tags {
  margin-top: 20px;
  color: #6f7287;
}

.location {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}

.location .location_text_info {
  margin-left: 1em;
  margin-bottom: 0.2em;
  font-size: 14px;
  font-weight: 600;
  color: #6f7287;
}

.location .location_text_info .text {
  margin-top: 0.6em;
}

.about-event {
  display: flex;
  flex-direction: row;
}

.popup {
  width: 40vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh 0;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content form {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* .content form h2 {
  margin-bottom: 2vh;
}

.country-select {
  margin-bottom: 1em;
} */

.about-event .about-event-text {
  margin-left: 1em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.about-event-description p {
  font-weight: 400;
  color: #6f7287;
  font-size: 16px;
  line-height: 1.5rem;
}

.payment-info {
  margin-top: 20px;
  padding: 10px;
}

.larger-than-800 {
  display: block;
}

.payment-info .price {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.payment-info .button {
  width: 100%;
  background-color: #d1410c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.analysis-smaller-than-800 {
  display: none;
}

.bonus {
  width: fit-content;
  padding-bottom: 1vmax;
}

@keyframes jump {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
    color: #d1410c;
  }
}

.curriculum-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.curriculum-form {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.curriculum-form h3 {
  font-size: 2vmax;
  margin: 0;
}

.curriculum-form form {
  width: 90%;
  height: 100%;
}

.curriculum-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.curriculum-form button {
  margin-right: 10px;
  padding: 10px 20px;
  background: #d1410c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.closing-confirm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.closing-confirm > .closing-confirm-popup {
  background-color: white;
  height: 60%;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5vh;
  padding: 1vmax;
  border-radius: 0.5vmax;
  position: relative;
}

.closing-confirm > .closing-confirm-popup > .close-of-closing {
  position: absolute;
  top: 0;
  right: 3%;
  cursor: pointer;
  font-size: 2vmax;
  font-weight: 500;
}

.closing-confirm > .closing-confirm-popup > h3 {
  font-size: 1.5vmax;
  width: 80%;
  text-align: center;
  margin: 0;
}

.closing-confirm > .closing-confirm-popup > p {
  font-size: 1.1vmax;
  width: 90%;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin: 0;
}

.closing-confirm > .closing-confirm-popup > a {
  text-decoration: none;
  background-color: transparent;
  width: 60%;
  height: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
}

.closing-confirm > .closing-confirm-popup > button {
  border: none;
  width: 60%;
  height: 16%;
  color: white;
  background-color: #d1410c;
  cursor: pointer;
  font-size: 1vmax;
}

@media (max-width: 1250px) {
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .analysis-smaller-than-800 {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8%;
  }
  .analysis-smaller-than-800 button {
    margin-top: 1em;
    width: 100%;
    height: 5vh;
    font-size: 1.5vmax;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }
}
@media (max-width: 1024px) {
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .analysis-smaller-than-800 {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .analysis-smaller-than-800 button {
    margin-top: 1em;
    padding: 10px 20px;
    width: 100%;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }
}
@media (max-width: 800px) {
  .event-details .event-details-header .left-half-event-details .page-title {
    font-size: 3vmax;
  }
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .analysis-smaller-than-800 {
    display: block;
    height: 5vh;
    position: fixed;
    bottom: 2.5%;
    width: 100%;
    background-color: #fcffe2;
  }
  .analysis-smaller-than-800 p {
    font-size: 1.7vmax;
  }
  .analysis-smaller-than-800 button {
    margin-top: 1em;
    padding: 10px 20px;
    width: 100%;
    font-size: 1.6vmax;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }
}
@media (max-width: 600px) {
  body {
    padding: 0 1vmax;
  }
  .event-details .event-details-header .left-half-event-details .page-title {
    font-size: 3vmax;
  }
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .analysis-header img {
    width: 100%;
    height: 100%;
    margin: 1vh 0;
    margin-top: 2vh;
    object-fit: contain;
  }
  .larger-than-800 {
    display: none;
  }
  .analysis-smaller-than-800 {
    display: block;
    height: 5vh;
    position: fixed;
    bottom: 3.5%;
    width: 100%;
    background-color: #fcffe2;
  }
  .analysis-smaller-than-800 p {
    font-size: 1.7vmax;
  }
  .analysis-smaller-than-800 button {
    width: 100%;
    height: 100%;
    font-size: 1.8vmax;
    color: #fff;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }

  .overlay .popup {
    height: 55vh;
    width: 90vw;
  }

  .content form {
    height: 90%;
    justify-content: space-evenly;
  }

  .closing-confirm > .closing-confirm-popup {
    width: 95%;
  }

  .closing-confirm > .closing-confirm-popup > .close-of-closing {
    font-size: 4vmax;
    top: -2%;
  }

  .closing-confirm > .closing-confirm-popup > h3 {
    width: 100%;
    font-size: 2.25vmax;
  }

  .closing-confirm > .closing-confirm-popup > p {
    width: 100%;
    font-size: 1.75vmax;
  }

  .closing-confirm > .closing-confirm-popup > button {
    font-size: 2vmax;
  }

  .closing-confirm > .closing-confirm-popup > a {
    font-size: 2vmax;
  }
}
@media (max-width: 400px) {
  body {
    padding: 0 0.1vmax;
  }
  .event-details .event-details-header .left-half-event-details .page-title {
    font-size: 2.5vmax;
    margin: 0.5vh 0;
  }
  .upcoming-event-container {
    margin: 10px;
    padding: 10px;
  }
  .analysis-header img {
    width: 100%;
    height: 100%;
    margin: 1vh 0;
    margin-top: 2vh;
    object-fit: contain;
  }
  .event-details-header {
    margin-bottom: 15px;
    flex-direction: column;
  }
  .event-details-header .left-half-event-details {
    align-items: flex-start;
  }
  .event-details-header .right-half-event-details {
    display: none;
  }
  .box {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  }
  .larger-than-800 {
    display: none;
  }
  .analysis-smaller-than-800 {
    display: block;
    height: 5vh;
    position: fixed;
    bottom: 3%;
    width: 100%;
    background-color: #fcffe2;
  }
  .analysis-smaller-than-800 p {
    font-size: 1.7vmax;
  }
  .analysis-smaller-than-800 button {
    width: 100%;
    height: 100%;
    font-size: 1.8vmax;
    color: #fff;
    background-color: #d1410c;
  }
  .right-half-event-details {
    display: none;
  }

  .overlay .popup {
    height: 55vh;
    width: 90vw;
  }

  .content form {
    height: 90%;
    justify-content: space-evenly;
  }

  .closing-confirm > .closing-confirm-popup {
    width: 95%;
  }

  .closing-confirm > .closing-confirm-popup > .close-of-closing {
    font-size: 4vmax;
    top: -2%;
  }

  .closing-confirm > .closing-confirm-popup > h3 {
    width: 100%;
    font-size: 2.25vmax;
  }

  .closing-confirm > .closing-confirm-popup > p {
    width: 100%;
    font-size: 1.75vmax;
  }

  .closing-confirm > .closing-confirm-popup > button {
    font-size: 2vmax;
  }

  .closing-confirm > .closing-confirm-popup > a {
    font-size: 2vmax;
  }
}
@media (max-width: 360px) {
  .event-details .event-details-header .left-half-event-details .page-title {
    font-size: 2vmax;
  }
  .analysis-smaller-than-800 button {
    font-size: 1.9vmax;
    color: #fff;
    border: none;
  }
}
