.question-image {
  display: block;
  margin: 0 auto;
  height: 35vh;
  object-fit: contain;
  margin-bottom: 2vh;
}

.option {
  padding: 1.7vh 0.75vw;
  margin: 1.5vh 0;
  background: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0.5vmax;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1vmax;
}

.option:hover {
  background: #ebe5f5;
}

.option.selected {
  background: #d7c6f3;
  color: rgb(0, 0, 0);
}

.next-btn {
  margin-top: 1vh;
  padding: 1.5vh 2.5vw;
  background: #d1410c;
  font-weight: 500;
  font-size: 1.05vmax;
  color: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.next-btn:disabled {
  background: #dcdcdc;
  color: #00000086;
  cursor: not-allowed;
}

.next-btn:not(:disabled):hover {
  background: #e04e18;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .question-container {
    width: 90%;
    margin: 0 auto;
  }

   .question-container .question-image {
    height: 25vh;
    margin-bottom: 1vh;
  }

  .option {
    padding: 1.5vh 1vw;
    margin: 1vh 0;
    font-size: 1.3vmax;
    border-radius: 0.3vmax;
  }

  .next-btn {
    margin-top: 0.6vh;
    padding: 1.25vh 5vw;
    font-size: 1.2vmax;
    border-radius: 3px;
  }
}

/* Responsive styles for max-width 400px */
@media (max-width: 400px) {
  .question-container {
    width: 90%;
    margin: 0 auto;
  }

   .question-container .question-image {
    height: 25vh;
    margin-bottom: 1vh;
  }

  .option {
    padding: 1.5vh 1vw;
    margin: 1vh 0;
    font-size: 1.3vmax;
    border-radius: 0.3vmax;
  }

  .next-btn {
    margin-top: 0.6vh;
    padding: 1.25vh 5vw;
    font-size: 1.2vmax;
    border-radius: 3px;
  }
}
