.event-container {
  width: 100%;
  height: 110vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 5vh;
}

.event-box1 {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.7);
}

.event-box1 .content-div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-box1 .content-div h3 {
  font-size: 1.6em;
  text-align: center;
  margin: 0;
}

.event-box1 .content-div ul {
  font-size: 0.9em;
  font-weight: 400;
  padding: 0;
  width: 90%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-box1 .content-div ul li {
  width: 100%;
  list-style-type: disc;
  list-style-position: inside;
}

.event-box1 .content-div a {
  text-decoration: none;
  background: #fbb83b;
  height: 15%;
  padding: 0 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: black;
  font-weight: normal;
  transition: all 0.2s;
  font-size: 1vmax;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.event-box1 .content-div a:hover {
  scale: 1.02;
}

.event-box1 .img-div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
}

.event-box1 > .img-div > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-box1 > .img-div > a > img {
  height: 90%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  object-fit: contain;
  border-radius: 8px;
  transition: all 0.2s;
}

.event-box1 .img-div img:hover {
  scale: 1.02;
}

/* For devices with a max width of 600px */
@media (max-width: 600px) {
  .event-container {
    height: fit-content;
    gap: 1vh;
  }

  .event-box1 {
    width: 95%;
    height: 50vh;
    flex-direction: column-reverse;
  }

  .event-box1 .content-div {
    width: 100%;
    height: 60%;
    gap: 0.5vh;
  }

  .event-box1 .content-div h3 {
    font-size: 2vmax;
    text-align: center;
    width: 95%;
  }

  .event-box1 .content-div ul {
    font-size: 1.6vmax;
    width: 95%;
    list-style-position: inside;
  }

  .event-box1 .content-div ul li {
    text-align: start;
  }

  .event-box1 .content-div a {
    height: 35px;
    width: 90%;
    font-size: 0.9rem;
  }

  .event-box1 .img-div {
    width: 100%;
    height: 45%;
  }

  .event-box1 .img-div img {
    height: 100%;
    border-radius: 5px;
  }
}

/* For devices with a max width of 400px */
@media (max-width: 400px) {
  .event-container {
    height: fit-content;
    gap: 1vh;
  }

  .event-box1 {
    width: 95%;
    height: 50vh;
    flex-direction: column-reverse;
  }

  .event-box1 .content-div {
    width: 100%;
    height: 60%;
    gap: 0.5vh;
  }

  .event-box1 .content-div h3 {
    font-size: 2vmax;
    text-align: center;
    width: 95%;
  }

  .event-box1 .content-div ul {
    font-size: 1.6vmax;
    width: 95%;
    list-style-position: inside;
  }

  .event-box1 .content-div ul li {
    text-align: start;
  }

  .event-box1 .content-div a {
    height: 35px;
    width: 90%;
    font-size: 0.9rem;
  }

  .event-box1 .img-div {
    width: 100%;
    height: 45%;
  }

  .event-box1 .img-div img {
    height: 100%;
    border-radius: 5px;
  }
}
