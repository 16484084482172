.intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 5vh 1.5vmax;
  /* background-color: rgba(255, 255, 255, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5vmax;
  padding: 2vh 0;
}

.intro-image {
  display: block;
  margin: 0 auto;
  height: 30vh;
  object-fit: contain;
  margin-bottom: 1vh;
}

.intro-section h2 {
  font-size: 1.6vmax;
  margin: 0;
}

.intro-section p {
  font-size: 1.1vmax;
  color: #555;
}

.intro-section > .intro-btn {
  padding: 1.5vh 2vw;
  font-size: 1vmax;
  border: none;
  color: white;
  cursor: pointer;
  background-color: #d1410c;
}

.intro-section > .intro-btn:active{
    scale: 0.95;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .proposal-quiz-cont .intro-section {
    height: fit-content;
    padding: 1vh 0.5vmax;
    border-radius: 0.3vmax;
  }

  .proposal-quiz-cont .intro-section .intro-image {
    height: 20vh;
    margin-bottom: 0.8vh;
  }

  .proposal-quiz-cont  .intro-section h2 {
    font-size: 1.6vmax;
  }

  .proposal-quiz-cont .intro-section p {
    font-size: 1.4vmax;
  }

  .proposal-quiz-cont .intro-section .intro-btn {
    padding: 1vh 5vw;
    font-size: 1.2vmax;
  }
}

/* Responsive styles for max-width 400px */
@media (max-width: 400px) {

  .proposal-quiz-cont .intro-section {
    height: fit-content;
    padding: 1vh 0.5vmax;
    border-radius: 0.3vmax;
  }

  .proposal-quiz-cont .intro-section .intro-image {
    height: 20vh;
    margin-bottom: 0.8vh;
  }

  .proposal-quiz-cont  .intro-section h2 {
    font-size: 1.6vmax;
  }

  .proposal-quiz-cont .intro-section p {
    font-size: 1.4vmax;
  }

  .proposal-quiz-cont .intro-section .intro-btn {
    padding: 1vh 5vw;
    font-size: 1.2vmax;
  }
}
