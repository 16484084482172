.faq-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 2em 3em;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
  margin-top: 2em;
}

.faq-section .faq-header {
  text-align: center;
  margin-bottom: 1.5em;
}

.faq-section .faq-header h1 {
  font-size: 2.5em;
  color: #333;
  margin: 0;
}

.faq-section .faq-header p {
  font-size: 1em;
  color: #666;
  margin-top: 0.5em;
}

.faq-section .faq-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.faq-section .faq-item {
  padding: 1em;
  border: 2px solid #fbb83b;
  border-radius: 8px;
  transition: all 0.3s;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-section .faq-item:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.faq-section .faq-item h2 {
  font-size: 1.5em;
  color: #333;
  margin: 0;
}

.faq-section .faq-item p {
  font-size: 1em;
  color: #666;
  margin-top: 0.5em;
  display: none; /* Hide initially */
  transition: all 0.3s ease-in-out;
}

.faq-section .faq-item.active p {
  display: block; /* Show when active */
}

.faq-section .faq-item .icon {
  float: right;
  font-size: 1.5em;
  color: #fbb83b;
  transition: transform 0.3s;
}

.faq-section .faq-item.active .icon {
  transform: rotate(180deg);
}
.faq-section .faq-item .faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faq-section .faq-item .faq-question span{
    font-size: 2.2em;
}

/* Responsive Design */
@media (max-width: 800px) {
  .faq-section {
    padding: 1.5em 2em;
  }

  .faq-section .faq-header h1 {
    font-size: 2em;
  }

  .faq-section .faq-item h2 {
    font-size: 1.2em;
  }

  .faq-section .faq-item p {
    font-size: 0.9em;
  }
}

@media (max-width: 400px) {
  .faq-section {
    padding: 1em 1.5em;
  }

  .faq-section .faq-header h1 {
    font-size: 1.8em;
  }

  .faq-section .faq-item h2 {
    font-size: 1em;
  }

  .faq-section .faq-item p {
    font-size: 0.8em;
  }
}
