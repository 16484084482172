.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
}

.thank-you-message {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.thank-you-message h1 {
  font-size: 2.5rem;
  color: #4caf50;
  margin-bottom: 20px;
}

.thank-you-message p {
  font-size: 1.2rem;
  color: #333333;
  margin-bottom: 10px;
}

.order-details {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.order-details h2 {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 15px;
}

.order-details p {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 5px;
}

.back-home-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-home-button:hover {
  background-color: #45a049;
}

/* Additional styling for the checkbox */
.checkbox-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Align items starting from the left */
  height: 5vh;
  width: 80%;
  padding: 0 10px; /* Add some padding for spacing */
  box-sizing: border-box; /* Ensure padding doesn't affect the width */
}

.checkbox-container input[type="checkbox"] {
  height: 20px; /* Allow default size for proper rendering */
  width: 20px; /* Allow default size for proper rendering */
  margin-right: 10px; /* Add some space between checkbox and label */
}

.checkbox-container label {
  font-size: 1rem;
  color: #333333;
  margin-top: 2vh;
  line-height: 1; /* Adjust line height for vertical centering */
}

/* Media Queries for Mobile Responsiveness */

@media only screen and (max-width: 1280px) {
  .thank-you-container {
    min-height: 55vh; /* For very small mobile devices */
  }
}

@media only screen and (max-width: 800px) {
  .thank-you-message {
    padding: 20px;
  }

  .thank-you-message h1 {
    font-size: 2rem;
  }

  .thank-you-message p {
    font-size: 1rem;
  }

  .order-details {
    padding: 15px;
    max-width: 100%;
  }

  .order-details h2 {
    font-size: 1.25rem;
  }

  .order-details p {
    font-size: 0.9rem;
  }

  .back-home-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .thank-you-container {
    min-height: 55vh; /* Adjust height for tablets and larger mobiles */
  }
}

@media only screen and (max-width: 480px) {
  .thank-you-container {
    min-height: 60vh; /* Adjust height for small mobile screens */
  }

  .thank-you-message {
    padding: 15px;
  }

  .thank-you-message h1 {
    font-size: 1.8rem;
  }

  .thank-you-message p {
    font-size: 0.9rem;
  }

  .order-details {
    padding: 10px;
    max-width: 100%;
  }

  .order-details h2 {
    font-size: 1.1rem;
  }

  .order-details p {
    font-size: 0.8rem;
  }

  .back-home-button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 360px) {
  .thank-you-container {
    min-height: 55vh; /* For very small mobile devices */
  }
}
