body {
  background-color: #d7c6f3;
}

.page-header {
  text-align: center;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-bottom: 2vmax;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.page-header .title {
  font-size: 60px;
  margin-bottom: 0px;
}
.page-header .subtitle {
  margin-top: 1px;
  font-size: large;
  margin-bottom: 2em;
}

.highlight-button {
  background-color: #fbb83b;
  padding: 1em 0.1em;
  font-size: medium;
  color: black;
  border-radius: 1em;
  transition: all 0.3s;
  width: 20%;
  text-align: center;
  cursor: pointer;
  border: none;
  margin-top: 35vh;
  margin-left: 3vw;
}

.highlight-button:hover,
.highlight-button:focus,
.highlight-button:active {
  transform: scale(1.05);
  background-color: #fbb83b; /* Maintain the same background color on hover */
  color: black; /* Ensure text color remains the same */
}

.video-section {
  width: 100%;
  height: 60vh;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* .video-section h1 {
  text-decoration: underline;
  margin: 0;
  margin-top: 1vmax;
}

.video-section button {
  width: 15%;
  padding: 1em 0;
  font-size: 1vmax;
  font-family: Roboto;
  color: black;
  margin: 1em 0;
  background: #fbb83b;
  font-weight: 500;
} */

/* .video-section button:hover,
.video-section button:focus,
.video-section button:active {
  transform: scale(1.05);
  background-color: #fbb83b;
  color: black;
} */

.video-section .video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.video-section .video-container .video-box {
  width: 48%;
  height: 90%;
  position: relative;
  margin: 0 auto;
}

.video-section .video-container .video-content {
  width: 48%;
  height: 90%;
  padding: 0.5vmax;
}

.video-section .video-container .video-content h2 {
  margin: 0;
  font-size: 2vmax;
}

.video-section .video-container .video-content p {
  font-size: 0.95vmax;
  margin-top: 2vmax;
}

.video {
  width: 100%;
  height: 99.9%;
  object-fit: cover;
}

/* .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 25%;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content h2 {
  margin: 0;
  margin-top: 1vmax;
}

.popup-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.popup-content form input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup-content form .btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1vh;
}

.popup-content form .btn-box button {
  background-color: #fbb83b;
  width: 40%;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.popup-content form .btn-box button:hover {
  transform: scale(1.05);
}

.popup-content .btn-box .close-btn {
  background-color: #000;
  color: #fbb83b;
} */

.achivements {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 24px 40px;
  border-radius: 8px;
}
.achivements .highlights {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1em;
}

.achivements .highlight-button {
  background-color: #fbb83b;
  padding: 1.3em 0.1em;
  font-size: medium;
  color: black;
  border-radius: 1em;
  transition: all 0.3s;
  margin: 1em auto;
  width: 20%;
  text-align: center;
  border: none;
  cursor: pointer; /* Pointer cursor for better UX */
}

.achivements .highlight-button:hover,
.achivements .highlight-button:focus,
.achivements .highlight-button:active {
  transform: scale(1.05);
}

.achivements .highlights .cards {
  padding: 18px 12px;
  margin-left: 14px;
  border-radius: 0.7em;
  border: 2px solid gray;
  box-shadow: 2px 2px rgb(148, 161, 165);
  text-align: center;
}
.achivements .highlights .cards .image {
  font-size: 40px;
  padding: 15px 40px;
  margin-bottom: 0px;
  display: inline-block;
  padding-bottom: 0;
  margin-top: -60px;
  background: #fff;
}
.achivements .highlights .cards .card-number {
  font-size: 35px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
}
.achivements .highlights .cards .card-text {
  color: #333;
  font-size: 20px;
  margin-top: 1px;
  margin-bottom: 4px;
}

.testimonials {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 2em 2em;
  border-radius: 8px;
}
.testimonials .testimonials-header {
  text-align: center;
}
.testimonials .testimonials-items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2em;
}
.testimonials .testimonials-items .cards {
  padding: 2.1em 1.4em;
  margin-left: 8px;
  border-radius: 0.7em;
  background-color: #d7c6f3;
  box-shadow: 2px 2px rgb(148, 161, 165);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  body {
    overflow-x: hidden;
  }

  .page-header {
    height: 22.5vh;
    width: 90%;
    background-size: contain;
    background-position: center;
    margin-left: 2.5vmax;
  }

  .page-header button {
    margin-top: 19%;
    font-size: 1.4vmax;
    width: 35%;
    padding: 1vmax 0;
  }

  .achivements {
    width: 78%;
    margin-top: 3vmax;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .achivements .highlights {
    flex-direction: column;
  }
  .achivements .highlights .cards {
    margin-top: 1em;
  }
  .achivements .highlight-button {
    width: 80%;
    padding: 1em 0.5em;
    font-size: 1em;
  }

  .testimonials {
    width: 80%;
    margin-left: 2vmax;
  }

  .video-section {
    width: 90%;
    margin-left: 2vmax;
    justify-content: space-evenly;
  }

  .video-section .video-container {
    flex-direction: column;
  }

  .video-section .video-container .video-box {
    width: 90%;
    height: 50%;
  }

  .video-section .video-container .video-content {
    width: 90%;
    height: 50%;
  }

  .video-section .video-container .video-content h2 {
    font-size: 3vmax;
  }

  .video-section .video-container .video-content p {
    font-size: 1.2vmax;
    margin-top: 0.5vmax;
  }

  .popup-content {
    width: 90%;
  }

  .testimonials .testimonials-items {
    flex-direction: column;
  }
  .testimonials .testimonials-items .cards {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1050px) {
  .page-header {
    background-size: contain;
    height: 38vh;
  }

  .video-section .video-container .video-content h2 {
    margin: 1vh 0;
  }

  .video-section .video-container .video-content p {
    font-size: 0.9em;
  }

  .achivements {
    margin-left: 2vmax;
  }
}

@media only screen and (min-width: 819px) and (max-width: 1200px) {
  body {
    padding: 0 2vw;
  }

  .page-header {
    background-size: cover;
  }

  .page-header button {
    margin-top: 20%;
  }

  .video-section .video-container .video-content h2 {
    margin: 1vh 0;
  }

  .video-section .video-container .video-content p {
    font-size: 0.9em;
  }

  .achivements {
    margin-left: 2vmax;
  }

  .video-section h1 {
    font-size: 2.5em;
    width: 90%;
  }

  .video-section .video-container {
    flex-direction: column;
  }

  .video-section .video-container .video-box {
    width: 90%;
    height: 50%;
  }

  .video-section .video-container .video-content {
    width: 90%;
    height: 50%;
  }

  .video-section .video-container .video-content h2 {
    font-size: 3vmax;
  }

  .video-section .video-container .video-content p {
    font-size: 1.2vmax;
    margin-top: 0.5vmax;
  }

  .video-section button {
    width: 50%;
    height: 10%;
    margin: 0;
    font-size: 1.4em;
  }

  .testimonials-header {
    margin: 0;
  }

  .testimonials .testimonials-items {
    flex-direction: column;
    height: 80%;
    align-items: center;
  }

  .testimonials {
    height: 100vh;
    padding: 0 1em;
    align-items: center;
    justify-content: space-evenly;
  }

  .testimonials .testimonials-items .cards {
    width: 70%;
    height: 20%;
  }

  .testimonials .testimonials-items .cards p {
    font-size: 1.1em;
  }

  .testimonials .testimonials-items .cards .quotes {
    font-size: 1.3em;
    margin: 0;
  }
}

@media only screen and (max-width: 800px) {
  .highlight-button {
    width: 80%;
    padding: 1em 0.5em;
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .page-header {
    background-size: cover;
  }

  .video-section .video-container .video-content p {
    font-size: 0.57em;
  }

  .achivements {
    height: 115vh;
  }

  .testimonials {
    height: 140vh;
    padding: 0 1em;
    align-items: center;
    justify-content: space-evenly;
  }

  .testimonials-header {
    margin: 0;
    margin-top: 0.5em;
  }

  .testimonials .testimonials-items {
    margin-bottom: 0;
  }

  .testimonials .testimonials-items .cards {
    padding: 0.5em 1em;
  }
}

@media (max-width: 400px) {
  .page-header {
    background-size: cover;
  }

  .video-section .video-container .video-content p {
    font-size: 0.57em;
  }

  .achivements {
    height: 115vh;
  }

  .testimonials {
    height: 140vh;
    padding: 0 1em;
    align-items: center;
    justify-content: space-evenly;
  }

  .testimonials-header {
    margin: 0;
    margin-top: 0.5em;
  }

  .testimonials .testimonials-items {
    margin-bottom: 0;
  }

  .testimonials .testimonials-items .cards {
    padding: 0.5em 1em;
  }
}
