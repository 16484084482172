.footer{
    text-align: center;
    background: #d7c6f3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 1em;
}


.social-media{
    cursor: pointer;
    margin: 0 0.5vmax;
}

.footer-p{
    font-size: 0.95em;
    font-weight: 400;
    width: 80%;
    margin: auto;
    margin-bottom: 1vmax;
}


/* Mobile Responsiveness */
@media (max-width: 800px) {
    .footer {
        padding: 15px;
    }

    .footer h1 {
        font-size: 20px;
    }

    .footer p {
        width: 95%;
        font-size: 14px;
        margin: 8px auto;
    }

    .social-media {
        margin: 5px;
    }

    .footer a {
        display: inline-block;
        margin: 5px 0;
    }
}