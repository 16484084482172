.proposal-quiz-cont {
  width: 70%;
  min-height: 99.8vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
   overflow: hidden; 
  position: relative; 
}


@media (max-width: 600px) {
  .proposal-quiz-cont {
    width: 100%;
    height: 100vh;
    padding: 0;
  }

  .question-image {
    display: block;
    margin: 0 auto;
    height: 30vh;
    object-fit: contain;
  }

  .quiz-option {
    width: 90%;
    height: 7vh;
    box-sizing: border-box;
    font-size: 1.8vmax;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding-left: 3vw;
    margin: 0 auto;
  }

  .question-text {
    font-size: 2.1vmax;
    font-weight: 500;
    width: 90%;
    margin: 2vh auto;
  }

  .intro-section {
    height: 30vh;
    width: 90%;
    gap: 1vh;
    margin: 0 auto;
  }

  .intro-section h2 {
    font-size: 2.5vmax;
  }

  .intro-section p {
    font-size: 1.5vmax;
  }

  .fact-text {
    margin: 0 auto;
    margin-top: 15px;
    font-size: 16px;
    width: 90%;
    font-style: italic;
    border-radius: 0.25vmax;
  }

  .user-form {
    width: 90%;
    padding: 1.25vmax;
  }
}

@media (max-width: 400px) {
  .proposal-quiz-cont {
    width: 100%;
    height: 100vh;
    padding: 0;
  }

  .question-image {
    display: block;
    margin: 0 auto;
    height: 30vh;
    object-fit: contain;
  }

  .quiz-option {
    width: 90%;
    height: 7vh;
    box-sizing: border-box;
    font-size: 1.8vmax;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding-left: 3vw;
    margin: 0 auto;
  }

  .question-text {
    font-size: 2.1vmax;
    font-weight: 500;
    width: 90%;
    margin: 2vh auto;
  }

  .intro-section {
    height: 30vh;
    width: 90%;
    gap: 1vh;
    margin: 0 auto;
  }

  .intro-section h2 {
    font-size: 2.5vmax;
  }

  .intro-section p {
    font-size: 1.5vmax;
  }

  .fact-text {
    margin: 0 auto;
    margin-top: 15px;
    font-size: 16px;
    width: 90%;
    font-style: italic;
    border-radius: 0.25vmax;
  }

  .user-form {
    width: 90%;
    padding: 1.25vmax;
  }
}
