.testimonial-cont {
  width: 100%;
  height: 50vh;
  margin-bottom: 2em;
}

.testimonial-cont > h1 {
  display: inline-block;
  margin: 0;
  font-size: 1.6em;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.testimonial-cont > .card-cont {
  width: 100%;
  height: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 0.75em;
}

.testimonial-cont > .card-cont > .cards {
  width: 33%;
  height: 90%;
  border-radius: 8px;
  background: #d7c6f3;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1em;
  padding: 1em;
}

.testimonial-cont > .card-cont > .cards > .r-text {
  font-size: 1em;
  font-weight: 400;
}

.testimonial-cont > .card-cont > .cards > .r-name {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1em;
  width: 100%;
}

.testimonial-cont > .card-cont > .cards > .r-name > img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid black;
}

@media (max-width: 800px) {
  .testimonial-cont {
    height: 100vh;
  }

  .testimonial-cont > h1 {
    margin: 0.5em 25%;
    width: fit-content;
    text-align: center;
  }

  .testimonial-cont > .card-cont {
    flex-direction: column;
  }

  .testimonial-cont > .card-cont > .cards {
    width: 90%;
  }

  .testimonial-cont > .card-cont > .cards > .r-text {
    font-size: 0.85em;
    font-weight: 400;
  }

  .testimonial-cont > .card-cont > .cards > .r-name {
    font-size: 0.85em;
  }
}

@media (max-width: 600px) {
  .testimonial-cont {
    height: fit-content;
  }

  .testimonial-cont > h1 {
    margin: 0.5em 25%;
    width: fit-content;
    text-align: center;
  }

  .testimonial-cont > .card-cont {
    flex-direction: column;
  }

  .testimonial-cont > .card-cont > .cards {
    width: 90%;
  }

  .testimonial-cont > .card-cont > .cards > .r-text {
    font-size: 0.85em;
    font-weight: 400;
  }

  .testimonial-cont > .card-cont > .cards > .r-name {
    font-size: 0.85em;
  }
}


@media (max-width: 400px) {
  .testimonial-cont {
    height: fit-content;
  }

  .testimonial-cont > h1 {
    margin: 0.5em 25%;
    width: fit-content;
    text-align: center;
  }

  .testimonial-cont > .card-cont {
    flex-direction: column;
  }

  .testimonial-cont > .card-cont > .cards {
    width: 90%;
  }

  .testimonial-cont > .card-cont > .cards > .r-text {
    font-size: 0.85em;
    font-weight: 400;
  }

  .testimonial-cont > .card-cont > .cards > .r-name {
    font-size: 0.85em;
  }
}
