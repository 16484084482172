.analysis-quiz-cont {
  width: 70%;
  height: 99.8vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.final-message-cont {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2vh;
}

.final-message-cont > .final-message-img {
  width: 25vw;
  margin: 0 auto;
  margin-bottom: 2vh;
}

.final-message-cont > .final-message-p {
  margin: 0;
  font-size: 1.1vmax;
}

.final-message-cont > .final-message-p .final-message-span {
  font-size: 1.5vmax;
}

.final-message-cont > .final-message-h4 {
  margin: 0;
  font-size: 1.3vmax;
}

.final-message-cont > .final-message-btn {
  margin-top: 1vh;
  padding: 1.5vh 1.5vw;
  background: #d1410c;
  font-weight: 500;
  font-size: 1.1vmax;
  border: none;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}

.final-message-cont > .final-message-btn:hover {
  background: #e04e18;
}

.progress-bar-container {
  width: 100%;
  height: 2.5vh;
  background-color: #e0e0e0;
  border-radius: 0.3vmax;
  overflow: hidden;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-bar {
  height: 100%;
  background-color: #D1410C; 
  transition: width 0.3s ease;
}

@media (max-width: 600px) {
  .analysis-quiz-cont {
    width: 100%;
    height: 100vh;
    padding: 0;
  }

  .final-message-cont {
    width: 90%;
    margin: 0 auto;
  }

  .final-message-cont > .final-message-img {
    width: 60vw;
  }

  .final-message-cont > .final-message-p {
    font-size: 1.5vmax;
  }

  .final-message-cont > .final-message-p .final-message-span {
    font-size: 2vmax;
  }

  .final-message-cont > .final-message-h4 {
    font-size: 1.8vmax;
  }

  .final-message-cont > .final-message-btn {
    font-size: 1.8vmax;
    padding: 1vh 4vw;
  }

    .progress-bar-container {
    height: 4vh; 
    top: 10%; 
    border-radius: 0.5vmax; 
    width: 90%;
  }
}

@media (max-width: 400px) {
  .analysis-quiz-cont {
    width: 100%;
    height: 100vh;
    padding: 0;
  }

  .final-message-cont {
    width: 90%;
    margin: 0 auto;
  }

  .final-message-cont > .final-message-img {
    width: 60vw;
  }

  .final-message-cont > .final-message-p {
    font-size: 1.5vmax;
  }

  .final-message-cont > .final-message-p .final-message-span {
    font-size: 2vmax;
  }

  .final-message-cont > .final-message-h4 {
    font-size: 1.8vmax;
  }

  .final-message-cont > .final-message-btn {
    font-size: 1.8vmax;
    padding: 1vh 4vw;
  }

  .progress-bar-container {
    height: 4vh; 
    top: 10%; 
    border-radius: 0.5vmax; 
    width: 90%;
  }
}
