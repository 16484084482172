.process {
  width: 100%;
  height: 90vh;
  background-color: white;
  border-radius: 8px;
}

.process h1 {
  text-align: center;
  margin-bottom: 0.2vmax;
}

.process .box {
  width: 100%;
  height: 87%;
  box-shadow: none;
}

.process .box .up {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.process .box .down {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.process .box .boxes {
  width: 40%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* box-shadow: 2px 2px rgb(148, 161, 165); */
  border-radius: 1vmax;
}

.process .box .boxes img {
  width: 35%;
  height: 35%;
  object-fit: contain;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Move up by 10px */
  }
  100% {
    transform: translateY(0); /* Return to the original position */
  }
}

.process .box .boxes h3 {
  margin: 0;
}

.process .box .boxes p {
  margin: 0;
  font-size: 1vmax;
  text-align: center;
  width: 90%;
}

@media (max-width: 600px) {
  .process {
    height: 110vh;
    width: 90%;
    margin: auto;
  }

  .process .box {
    width: 90%;
    height: 90%;
  }

  .process .box .up {
    flex-direction: column;
    width: 100%;
    height: 50%;
  }

  .process .box .down {
    flex-direction: column;
    width: 100%;
    height: 50%;
  }

  .process .box .boxes {
    width: 70%;
    margin-bottom: 1vmax;
    margin-left: 4vmax;
  }
}

@media (max-width: 400px) {
  .process {
    height: 150vh;
    width: 90%;
    margin: auto;
  }

  .process h1{
    padding-top: 5vh;
  }

  .process .box {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .process .box .up {
    flex-direction: column;
    width: 100%;
    height: 45%;
  }

  .process .box .down {
    flex-direction: column;
    width: 100%;
    height: 45%;
  }

  .process .box .boxes {
    width: 80%;
    }
}
