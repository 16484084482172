.navbar {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #d7c6f3;
  height: 12.5vh;
}
.navbar .logoImage {
  width: 20%;
  height: 75%;
  object-fit: contain;
  cursor: pointer;
}
.navbar div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1vmax;
  margin-right: 1vw;
}
.navbar div a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
  letter-spacing: 0.05vmax;
  font-size: 1.1vmax;
  padding: 0.5vmax 1vmax;
  border-radius: 0.5vmax;
  transition: all 0.2s ease-in-out;
}
.navbar div a:hover {
  background-color: #fbb83b;
}

@media only screen and (min-width: 768px) and (max-width: 1050px) {
  .navbar .logoImage {
    width: 20vw;
    height: 8vh;
    margin-left: 1vw;
  }

  .navbar .navLinks a {
    font-size: 0.9em;
  }
}

@media (max-width: 800px) {
  .navbar .logoImage {
    object-fit: contain;
    width: 30vw;
    height: 10vh;
  }

  .navbar .navLinks {
    height: 100%;
    width: 60%;
  }

  .navbar .navLinks a {
    font-size: 0.7em;
  }
}

@media (max-width: 600px) {
  .navbar {
    margin-top: 1vh;
    height: 8vh;
    padding: 0 2vw;
  }

  .navbar .logoImage {
    object-fit: contain;
    width: 30vw;
    height: 5vh;
  }
  .navbar .navLinks {
    height: 100%;
    width: 60%;
  }
  .navbar .navLinks a {
    font-size: 0.7em;
  }
}

@media (max-width: 400px) {
  .navbar {
    margin-top: 1vh;
    height: 8vh;
    padding: 0 2vw;
  }

  .navbar .logoImage {
    object-fit: contain;
    width: 30vw;
    height: 5vh;
  }
  .navbar .navLinks {
    height: 100%;
    width: 60%;
  }
  .navbar .navLinks a {
    font-size: 0.7em;
  }
}
