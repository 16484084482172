.analysis-intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 5vh 1.5vmax;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5vmax;
  padding: 2vh 1vw;
}

.analysis-intro-image {
  display: block;
  margin: 0 auto;
  height: 30vh;
  object-fit: contain;
  margin-bottom: 1vh;
}

.analysis-intro-section h2 {
  font-size: 1.6vmax;
  margin: 0;
}

.analysis-intro-section p {
  font-size: 1.1vmax;
  color: #555;
}

.analysis-intro-section > .analysis-intro-btn {
  padding: 1.5vh 2vw;
  font-size: 1vmax;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.5vmax;
  background-color: #d1410c;
}

.analysis-intro-section > .analysis-intro-btn:active {
  scale: 0.95;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .analysis-quiz-cont .analysis-intro-section {
    height: fit-content;
    padding: 1vh 0.5vmax;
    border-radius: 0.3vmax;
    width: 95%;
    margin: 0 auto;
  }

  .analysis-quiz-cont .analysis-intro-section .analysis-intro-image {
    height: 25vh;
    margin-bottom: 0.8vh;
  }

  .analysis-quiz-cont .analysis-intro-section h2 {
    font-size: 2.2vmax;
  }

  .analysis-quiz-cont .analysis-intro-section p {
    font-size: 1.4vmax;
    width: 90%;
  }

  .analysis-quiz-cont .analysis-intro-section .analysis-intro-btn {
    padding: 1vh 5vw;
    font-size: 1.5vmax;
  }
}

/* Responsive styles for max-width 400px */
@media (max-width: 400px) {
  .analysis-quiz-cont .analysis-intro-section {
    height: fit-content;
    padding: 1vh 0.5vmax;
    border-radius: 0.3vmax;
    width: 90%;
    margin: 0 auto;
  }

  .analysis-quiz-cont .analysis-intro-section .analysis-intro-image {
    height: 25vh;
    margin-bottom: 0.8vh;
  }

  .analysis-quiz-cont .analysis-intro-section h2 {
    font-size: 2.5vmax;
  }

  .analysis-quiz-cont .analysis-intro-section p {
    font-size: 1.4vmax;
    width: 90%;
  }

  .analysis-quiz-cont .analysis-intro-section .analysis-intro-btn {
    padding: 1vh 5vw;
    font-size: 1.5vmax;
  }
}
